import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ReadApiService } from '../@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private customersUrl: string = this.environment.getApiKey('customersUrl');
  private customerDataUrl: string = this.environment.getApiKey('customerDataUrl');
  private kycServiceUrl: string = this.environment.getApiKey('kycServiceUrl');

  getCustomerByPhoneNumber(phone) {
    return this.http.get(
      this.customersUrl + `/admin/api/v1/users/List?phoneNumber=${phone}&isIdentified=true&page=1&pageSize=20`,
    );
  }

  getUserByPhoneNumber(phone) {
    return this.http.get(
      this.customerDataUrl + `/admin/api/v1/customers?phoneNumber=${phone?.length > 0 && phone}&page=1&pageSize=20`,
    );
  }

  getUserById(id) {
    return this.http.get(this.customerDataUrl + `/admin/api/v1/customers/${id}`);
  }

  getUserByPin(pin) {
    return this.http.get(
      this.kycServiceUrl + `/admin/api/v1/identification-requests?pin=${pin}&statusList=Approved&page=1&pageSize=20`,
    );
  }
}
