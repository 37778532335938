<input
  #input
  type="text"
  nbInput
  fullWidth
  fieldSize="small"
  [placeholder]="placeholder"
  style="min-width: 200px"
  [formControl]="control" />
<!-- [placeholder]="placeholder" -->
