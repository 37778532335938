@if (type === 'input') {
  <input
    type="text"
    nbInput
    fullWidth
    [placeholder]="placeholder"
    [formControl]="control"
    [status]="submitted && control.errors?.['required'] ? 'danger' : 'basic'" />
}
@if (type === 'textarea') {
  <textarea
    type="text"
    nbInput
    fullWidth
    [placeholder]="placeholder"
    [formControl]="control"
    [status]="submitted && control.errors?.['required'] ? 'danger' : 'basic'"></textarea>
}
@if (control.errors?.['maxlength']) {
  <p class="validation-input">
    Максимальное количество символов
    {{ control.errors?.maxlength.requiredLength }}
  </p>
}
