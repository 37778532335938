<div class="header-card">
  <div>
    <p>Полное наименование на официальном языке</p>
  </div>

  <div class="detail">
    {{ data?.name }}
  </div>
</div>
<div class="header-card">
  <div>
    <p>Основной вид деятельности</p>
  </div>

  <div class="detail">
    {{ data?.mainActivity }}
  </div>
</div>

<div class="header-card">
  <div>
    <p>Организационно-правовая форма</p>
  </div>

  <div class="detail">
    {{ translateOwnerShipType(data.legalForm) }}
  </div>
</div>

<div class="header-card">
  <div>
    <p>Номер телефона</p>
  </div>

  <div class="detail">
    {{ data?.phone }}
  </div>
</div>
<div class="header-card">
  <div>
    <p>ИНН</p>
  </div>

  <div class="detail">
    {{ data?.tin }}
  </div>
</div>
<div class="header-card">
  <div>
    <p>ОКПО</p>
  </div>

  <div class="detail">
    {{ data?.okpo }}
  </div>
</div>
<div class="header-card">
  <div>
    <p>Дата основания</p>
  </div>

  <div class="detail">
    {{ data?.foundingDate }}
  </div>
</div>
<div class="header-card">
  <div>
    <p>Номер регистрации</p>
  </div>

  <div class="detail">
    {{ data?.registrationNumber }}
  </div>
</div>

<div class="header-card">
  <div>
    <p>Зарегистрированный адрес</p>
  </div>

  <div class="detail">
    {{ data?.registrationAddress }}
  </div>
</div>
<nb-card class="mt-3">
  <nb-card-header>Пасспортные данные</nb-card-header>
  <nb-card-body>
    <ngx-passport-details [customerData]="customerData" [showPhoneNumber]="true"></ngx-passport-details>
  </nb-card-body>
</nb-card>
