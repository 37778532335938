<nz-select
  ngDefaultControl
  [nzMode]="mode"
  class="w-100"
  nzAllowClear
  nzServerSearch
  [formControl]="control"
  nzShowSearch
  (nzOnSearch)="onSearch($event)"
  [nzSize]="size"
  [ngClass]="size === 'default' && 'small-input'"
  [nzPlaceHolder]="placeholder"
  [compareWith]="compareFn"
  nzNotFoundContent="Ничего не найдено"
  [nzStatus]="isRequired && submitted && control.errors?.['required'] ? 'error' : ''">
  @if (!isLoading) {
    @if (isSearch) {
      <nz-option nzValue="" nzLabel="Все"></nz-option>
    }
    @for (o of data; track o) {
      <nz-option [nzValue]="o.id" [nzLabel]="getLabel(o)"></nz-option>
    }
  }
  @if (isLoading) {
    <nz-option nzDisabled nzCustomContent>
      <span nz-icon nzType="loading" class="loading-icon"></span>
      Загрузка...
    </nz-option>
  }
</nz-select>
