import { Component } from '@angular/core';
import { Cell } from 'angular2-smart-table';

@Component({
  template: `<img
    width="43"
    height="43"
    class="bg-info rounded-circle mx-auto d-flex"
    [src]="renderValue | useHttpImgSrc" />`,
})
export class AvatarImgComponent {
  renderValue: string = '';
  public static componentInit(instance: AvatarImgComponent, cell: Cell) {
    instance.renderValue = cell.getValue().toString();
  }
}
