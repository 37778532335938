import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';
import { getTimezone } from '../../../../utils';

export const providerRateTypes = [
  {
    value: 'FixedRewardRate',
    title: 'Фиксированное вознаграждение от поставщика',
    rate: 'сом',
  },
  {
    value: 'PercentageRewardRate',
    title: 'Процентное вознаграждение от поставщика',
    rate: '%',
  },
  {
    value: 'FixedCompensationRate',
    title: 'Фиксированное возмещение поставщику',
    rate: 'сом',
  },
  {
    value: 'PercentageCompensationRate',
    title: 'Процентное возмещение поставщику',
    rate: '%',
  },
];

@Component({
  selector: 'ngx-providers-rates-actions-modal',
  templateUrl: './providers-rates-actions-modal.component.html',
})
export class ProvidersRatesActionsModalComponent implements OnInit, OnDestroy {
  rateTypes = providerRateTypes;
  form = this.fb.group({
    serviceId: [''],
    startDate: [''],
    rateType: [''],
    value: [''],
  });
  providerId;
  item;
  services = [];
  servicesData = [];
  submitted = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private paymentSystemService: PaymentSystemService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.getServices();

    if (this.item) {
      this.form.controls['serviceId'].setValue(this.item.providerServiceId);
      this.form.controls['startDate'].setValue(this.formatDate(this.item.startDate));
      this.form.controls['rateType'].setValue(this.item.rate.rateType);
      this.form.controls['value'].setValue(this.item.rate.rateValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getServices() {
    this.paymentSystemService
      .getListProviderServices(1, this.providerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.servicesData = res.items;
        this.services = res.items;
      });
  }

  public filterServicesData(value: string) {
    this.services = this.servicesData.filter(e => e.description.toLowerCase().includes(value.toLowerCase()));
  }

  public onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      if (!this.item) {
        this.paymentSystemService
          .addRate(this.providerId, this.form.value.serviceId, {
            ...this.form.value,
            startDate: this.form.value.startDate + 'T00:00' + getTimezone(true),
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
      if (this.item) {
        const parts = this.form.value.startDate.split('.');
        const formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

        this.paymentSystemService
          .editRate(this.providerId, this.item.providerServiceId, this.item.id, {
            ...this.form.value,
            startDate: formattedDate,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
    }
  }

  private formatDate(date: Date) {
    const newDate = new Date(date);
    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }
}
