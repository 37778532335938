<nb-card>
  <nb-card-body>
    <nb-card-header class="d-flex justify-content-between">
      <ngx-input-search (searchEvent)="getRates(1, $event)" placeholder="Название услуги"> </ngx-input-search>
      <button (click)="openAddServicesModal()" nbButton status="primary">Добавить</button>
    </nb-card-header>
    <ngx-custom-table
      class="mt-5 d-block"
      (changePageEvent)="getRates($event)"
      (deleteItemEvent)="deleteService($event)"
      (openModalEvent)="openEditModal($event)"
      [tableColumns]="tableColumns"
      [tableData]="listServices"
      [productName]="'ни одного тарифа'">
    </ngx-custom-table> </nb-card-body
></nb-card>
