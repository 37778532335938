export const HttpOptions: object = {
  responseType: 'blob',
};
export const paymentMethods = [
  { id: 'Wallet', name: 'Кошелек' },
  { id: 'Bonus', name: 'Бонусы' },
  { id: '_003', name: '0-0-3' },
];
export const statusIdentificate = [
  { id: 'Pending', text: 'Ожидание' },
  { id: 'Created', text: 'Ожидание' },
  { id: 'Approved', text: 'Одобренный' },
  { id: 'Declined', text: 'Отклоненный' },
  { id: 'NeedToEdit', text: 'Нужно отредактировать' },
  { id: 'Canceled', text: 'Отменено' },
  { id: 'Requested', text: 'Ожидание' },
  { id: 'InProcess', text: 'В процессе' },
  { id: 'EditRequired', text: 'Нужно отредактировать' },
  { id: 'Timeout', text: 'Время и стекло' },
  { id: 'Online', text: 'Онлайн' },
  { id: 'Postponed', text: 'Отложенный' },

  { id: 'Offline', text: 'Офлайн' },
  { id: 'None', text: 'Не идентифицирован' },

  {
    id: 'Active',
    text: 'Активен',
  },
  {
    id: 'InActive',
    text: 'Неактивен',
  },
  {
    id: 'Blocked',
    text: 'Заблокирован',
  },
  { id: true, text: 'Одобренный' },
  { id: false, text: 'Ожидание' },
  // ! Payment Reports
  { id: 'AgentNotFound', text: 'Агент не найден' },
  { id: 'AgentNotActive', text: 'Агент не активен' },
  {
    id: 'InsufficientAgentBalance',
    text: 'Недостаточно средств на балансе агента',
  },
  { id: 'ProviderNotFound', text: 'Провайдер не найден' },
  { id: 'ProviderNotActive', text: 'Провайдер не активен' },
  { id: 'ServiceNotFound', text: 'Услуга не найдена' },
  { id: 'ServiceNotActive', text: 'Услуга не активна' },
  { id: 'ParametersWrongFormat', text: 'Неверный формат параметров запроса' },
  { id: 'SumLessThan', text: 'Сумма платежа меньше установленного значения' },
  {
    id: 'SumGreaterThan',
    text: 'Сумма платежа больше установленного значения',
  },
  { id: 'AccessError', text: 'Ошибка доступа к системе провайдера' },
  { id: 'AbonentNotFound', text: 'Абонент не найден' },
  { id: 'AbonentNotActive', text: 'Абонент не активен' },
  { id: 'PaymentInProcessing', text: 'Платеж в обработке' },
  { id: 'TemporaryError', text: 'Временная ошибка' },
  { id: 'PaymentError', text: 'Платеж в ошибке' },
  { id: 'PaymentCanceled', text: 'Платеж отменён' },
  { id: 'Success', text: 'Успешно' },
  { id: 'NotSupported', text: 'Не поддерживаетсяа' },
  { id: 'New', text: 'Новый' },
  { id: 'WalletNotFound', text: 'Кошелёк не найден' },
  { id: 'WalletNotActive', text: 'Кошелёк не активен' },
  { id: 'ExceedsMaxWalletBalance', text: 'Превышен максимальный баланс кошелька' },
  { id: 'InsufficientSanaripBalance', text: 'Недостаточно средств на балансе Санарип' },
  { id: 'ExceedsOneTimeLimitAmount', text: 'Превышен единовременный лимит' },
  { id: 'ExceedsMonthlyLimitAmount', text: 'Превышен месячный лимит кошелька' },
  { id: 'CustomerFoundInBlackList', text: 'Найден в черном списке' },
  { id: 'FoundInBlackList', text: 'Найден в черном списке' },
  {
    id: 'ExceedsCrossBorderCharityMonthLimitAmount',
    text: 'Превышает месячный лимит трансграничной благотворительной помощи',
  },
  {
    id: 'ExceedsCrossBorderCharityOneTimeLimitAmount',
    text: 'Превышает единовременный лимит трансграничной благотворительной помощи',
  },
  { id: 'InBlackList', text: 'В черном списке' },
  { id: 'WalletNotActive', text: 'Кошелёк не активен' },
  { id: 'TransferToYourself', text: 'Перевод самому себе' },
  { id: 'Error', text: 'Операция в ошибке' },
  { id: 'InsufficientWalletBalance', text: 'Недостаточно средств на балансе кошелька' },
  { id: '101', text: 'Обнаружен в чёрном списке' },
  { id: '100', text: 'Отправитель найден в чёрном списке' },
];
