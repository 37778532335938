<button
  [nbSpinner]="isLoading | async"
  (click)="onClick()"
  nbSpinnerStatus="info"
  nbSpinnerSize="medium"
  nbSpinnerMessage=""
  nbButton
  [disabled]="isDisabled"
  [status]="status">
  {{ title }}
</button>
