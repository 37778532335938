<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Услуга</label>
    <div>
      <ngx-search-select
        class="w-100"
        [submitted]="submitted"
        [control]="form.get('serviceId')"
        (searchEmit)="filterServicesData($event)"
        [data]="services"
        returnVal="serviceName"
        [placeholder]="'Выберите услугу'"
        mode="default"></ngx-search-select>
    </div>
  </div>
  <div class="form-group">
    <label class="label">Тип ставки</label>
    <nb-select formControlName="rateType" fullWidth placeholder="Тип ставки">
      @for (item of rateTypes; track item) {
        <nb-option [value]="item.value">{{ item.title }}</nb-option>
      }
    </nb-select>
  </div>

  <div class="form-group">
    <label class="label">Действует с</label>
    <input
      formControlName="startDate"
      fullWidth
      onfocus="(this.type='date')"
      type="text"
      format="dd.MM.yyyy"
      nbInput
      placeholder="Действует с" />
  </div>

  <div class="form-group">
    <label class="label">Ставка</label>
    <input
      formControlName="value"
      fullWidth
      nbInput
      type="number"
      placeholder="Введите ставку"
      [status]="submitted && form.get('value').errors?.['required'] ? 'danger' : 'basic'" />
  </div>

  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Подтвердить</button>
</form>
