<div class="form-group">
  <label class="label">{{ label }} RU</label>
  @if (formControlType === 'input') {
    <ngx-validation-input [submitted]="submitted" [control]="controlRu" [placeholder]="placeholder + ' RU'">
    </ngx-validation-input>
  }
  @if (formControlType === 'textarea') {
    <ngx-validation-input
      [submitted]="submitted"
      [control]="controlRu"
      [placeholder]="placeholder + ' RU'"
      type="textarea">
    </ngx-validation-input>
  }
</div>

<div class="form-group">
  <label class="label">{{ label }} KG</label>
  @if (formControlType === 'input') {
    <ngx-validation-input [submitted]="submitted" [control]="controlKy" [placeholder]="placeholder + ' KG'">
    </ngx-validation-input>
  }
  @if (formControlType === 'textarea') {
    <ngx-validation-input
      [submitted]="submitted"
      [control]="controlKy"
      [placeholder]="placeholder + ' KG'"
      type="textarea">
    </ngx-validation-input>
  }
</div>
<div class="form-group">
  <label class="label">{{ label }} EN</label>
  @if (formControlType === 'input') {
    <ngx-validation-input [submitted]="submitted" [control]="controlEn" [placeholder]="placeholder + ' EN'">
    </ngx-validation-input>
  }
  @if (formControlType === 'textarea') {
    <ngx-validation-input
      [submitted]="submitted"
      [control]="controlEn"
      [placeholder]="placeholder + ' EN'"
      type="textarea">
    </ngx-validation-input>
  }
</div>
