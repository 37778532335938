import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';

@Component({
  selector: 'ngx-payment-system-provider-services',
  templateUrl: './list-payment-system-provider-services.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPaymentSystemProviderServicesComponent implements OnInit, OnDestroy {
  @Input() providerId;
  public listServices;
  public tableColumns = {
    serviceName: {
      title: 'Название',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    description: {
      title: 'Описание',
      type: 'text',
    },
    isActive: {
      title: 'Активен',
      type: 'text',
      valuePrepareFunction: (bool: boolean): string => (bool ? 'Да' : 'Нет'),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private paymentSystemService: PaymentSystemService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getServices();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getServices(page: number = 1) {
    this.paymentSystemService
      .getListProviderServices(page, this.providerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.listServices = res;
        this.cd.markForCheck();
      });
  }
}
