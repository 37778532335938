import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PaymentManagingService } from '../../../services/payment-managing/payment-managing.service';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';

@Component({
  selector: 'ngx-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnInit, OnDestroy {
  @Output() searchEmit = new EventEmitter<string>();
  @Output() getDataEvent = new EventEmitter<string>();

  @Input() control: FormControl = new FormControl();
  @Input() additionalControl: AbstractControl = new FormControl();
  @Input() selectedItems: string[] = [];

  @Input() submitted = false;
  @Input() isRequired = true;
  @Input() mode = 'default';
  @Input() size = 'large';
  @Input() placeholder: string;
  @Input() data;
  @Input() isSearch = false;
  @Input() returnVal = 'name';
  @ViewChild(NzTreeSelectComponent)
  treeSelect: NzTreeSelectComponent | undefined;
  public categoryList;
  private categoryData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private paymentManagingService: PaymentManagingService) {}

  ngOnInit(): void {
    this.getCategories();
    this.getParentIds();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onExpandChange(e: NzFormatEmitEvent): void {
    if (e) {
      const node: NzTreeNode = e.node;

      if (node && node.getChildren().length === 0 && node.isExpanded) {
        this.loadNode(node.key).then(data => {
          if (data) {
            node.addChildren(data);
            return;
          }
          node.isLeaf = true;
        });
      }
    }
  }

  private loadNode(key: string): Promise<any> {
    return new Promise(resolve => {
      // Assuming the key is the parentId in this case
      this.getCategoriesByParentId(key).subscribe(res => {
        resolve(res);
      });
    });
  }

  private convertTree(data) {
    return data.map(item => ({
      title: item.name.ru,
      key: item.id,
      order: item.order,
      isActive: item.isActive,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      logo: item.logo,
      parentId: item?.parentId,
    }));
  }

  private getParentIds() {
    if (this.additionalControl.value.length > 0) {
      this.additionalControl.value.map((item: string) => {
        this.getCategoriesByParentId(item).subscribe((res: any) => {
          this.fillCategoryList(res);
        });
      });
    }
  }

  private getCategoriesByParentId(parentId?: string) {
    return this.paymentManagingService.getListCategories(1, parentId).pipe(
      takeUntil(this.destroy$),

      map((res: any) => {
        if (res.items.length > 0) {
          return (this.categoryData = this.convertTree(res.items));
        }

        return (this.categoryData = null);
      }),
    );
  }

  private getCategories(parentIds?: string): void {
    this.paymentManagingService
      .getListCategories(1, parentIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.categoryList = this.convertTree(res.items);
      });
  }

  private fillCategoryList(children) {
    this.categoryList?.forEach(category => {
      const categoryChildren = children.filter(child => child.parentId === category.key);

      if (categoryChildren.length > 0) {
        category.children = categoryChildren;
        category.expanded = true;
      }
    });
  }
}
