import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { NzSelectModeType, NzSelectSizeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'ngx-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent implements OnInit, OnDestroy {
  @Output() searchEmit = new EventEmitter<string>();
  @Input() control: AbstractControl = new FormControl();

  @Input() submitted = false;
  @Input() isRequired = true;
  @Input() mode: NzSelectModeType = 'default';
  @Input() size: NzSelectSizeType = 'large';
  @Input() placeholder: string;
  @Input() data;
  @Input() isSearch = false;
  @Input() returnVal = 'name';

  isLoading = false;
  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (Array.isArray(data) && data.some(item => typeof item === 'object')) {
        this.control.setValue(data.map(item => item.id));
      }
    });
    this.control.valueChanges.pipe(takeUntil(this.destroy$), take(1)).subscribe(data => {
      if (data === null) {
        this.control.setValue(null);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  compareFn = (o1: any, o2: any) => (o1 && o2 ? (typeof o1 === 'object' ? o1.id === o2 : o1 === o2) : o1 === o2);

  onSearch(event) {
    this.searchEmit.emit(event);
  }

  getLabel(item: any): string {
    if (typeof item[this.returnVal] === 'object') {
      return item[this.returnVal].ru;
    }

    return item[this.returnVal];
  }
}
