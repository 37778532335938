<ng-container>
  <div class="image-container">
    <img width="80%" (click)="openImage()" [src]="imgUrl ? (imgUrl | useHttpImgSrc) : safeImgUrl" />
  </div>
  @if (open) {
    <div class="viewer-container">
      <div class="viewer-content">
        <button class="close-button" (click)="open = false">
          <i style="font-size: 50px" class="nb-close"></i>
        </button>
        <div class="image-container-light" #imageContainer>
          <img
            [src]="imgUrl ? (imgUrl | useHttpImgSrc) : safeImgUrl"
            class="main-img main-img-desktop"
            #imageElement
            (dblclick)="zoomIn()"
            (mouseup)="onImageMouseUp($event)"
            (mousedown)="onImageMouseDown($event)"
            (mousemove)="onImageMouseMove($event)"
            (dragstart)="onImageMouseMove($event)"
            (wheel)="onImageWheel($event)" />
          <img #imageElement [src]="imgUrl ? (imgUrl | useHttpImgSrc) : safeImgUrl" class="main-img-mobile" />
        </div>
        <div class="controls">
          <!-- <button nbButton (click)="toggleFullscreen()">
          На весь экран
        </button> -->
          <button nbButton (click)="zoomOut()">-</button>
          <button nbButton (click)="zoomIn()">+</button>
          <button nbButton (click)="rotateLeft()">↺</button>
          <button nbButton (click)="rotateRight()">↻</button>
        </div>
      </div>
    </div>
  }
</ng-container>
