<nb-card>
  <nb-card-body>
    <ngx-custom-table
      class="mt-5 d-block"
      (changePageEvent)="getServices($event)"
      [tableColumns]="tableColumns"
      [tableData]="listServices"
      [productName]="'ни одной услуги'"
      [delete]="false"
      [edit]="false">
    </ngx-custom-table>
  </nb-card-body>
</nb-card>
