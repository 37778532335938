import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new Subject<boolean>();

  constructor(private authService: AuthService) {}

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }
  checkIsAuditor() {
    const userAuthData = this.authService.getUserData();

    const isAuditor = userAuthData.role.includes('auditor');
    return isAuditor;
  }
}
