@if (categoryList) {
  <nz-tree-select
    #treeSelect
    style="width: 500px"
    [nzPlaceHolder]="placeholder"
    [nzNodes]="categoryList"
    (nzExpandChange)="onExpandChange($event)"
    nzCheckable
    nzCheckStrictly
    nzShowSearch
    nzShowExpand
    nzAsyncData
    nzDropdownMatchSelectWidth
    [formControl]="control"
    nzVirtualHeight="300px">
  </nz-tree-select>
}
