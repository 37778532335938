import { Component, OnDestroy, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PaymentManagingService } from '../../../../services/payment-managing/payment-managing.service';
import { LoaderService } from '../../../../services/@core/http/loader.service';

@Component({
  selector: 'ngx-subcategories-payment-managing',
  templateUrl: './subcategories-payment-managing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubcategoriesPaymentManagingComponent implements OnDestroy, OnChanges {
  @Input() categoryId;
  subcategories;
  tableColumns = {
    name: {
      title: 'Название',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    isActive: {
      title: 'Активен',
      type: 'text',
      valuePrepareFunction: bool => (bool ? 'Да' : 'Нет'),
    },
    order: { title: 'Порядок', type: 'number' },
    custom: {
      title: 'Детали',
      type: 'html',
      valuePrepareFunction: () => ` <a
                          class='color-a'
                        >
                          Подробнее
                        </a>`,
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private paymentManagingService: PaymentManagingService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public helpersService: LoaderService,
  ) {}

  getSubcategories(page = 1) {
    this.paymentManagingService
      .getListCategories(page, this.categoryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.subcategories = data;
        this.cdr.markForCheck();
      });
  }

  onRowSelect(item) {
    this.router.navigate(['/payment-managing/categories/detail/' + item.id]);
  }

  editCategory(item) {
    this.router.navigate([
      '/payment-managing/categories/detail' + '/update-subcategory/' + this.categoryId + '/' + item.id,
    ]);
  }

  ngOnChanges(): void {
    this.getSubcategories();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
