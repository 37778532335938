<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Название</label>
    <ngx-validation-input [submitted]="submitted" [control]="form.get('description')" placeholder="Введите название">
    </ngx-validation-input>
  </div>
  @if (!itemData) {
    <div class="form-group">
      <label class="label">Контрагент</label>
      <ngx-search-select
        [submitted]="submitted"
        [control]="form.get('id')"
        (searchEmit)="getCounteragents($event)"
        [data]="counteragents"
        returnVal="name"
        [placeholder]="'Выберите контрагента'"></ngx-search-select>
    </div>
    <div class="form-group">
      <label class="label">Пароль</label>
      <div class="d-flex">
        <input type="text" nbInput fullWidth placeholder="Введите пароль" formControlName="apiKey" />
        <button type="button" class="ms-2" (click)="generatePassword()" nbButton status="primary">Сгенерировать</button>
      </div>
    </div>
  }
  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Подтвердить</button>
</form>
