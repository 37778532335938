@if (data && data.totalItemCount > 20) {
  <div class="custom-pagination">
    <nz-pagination
      class="mx-auto"
      (nzPageIndexChange)="changePage($event)"
      [nzPageIndex]="data.page ? data.page : data.pageNumber"
      [nzTotal]="data.totalItemCount"
      [nzPageSize]="data.pageSize"></nz-pagination>
  </div>
}
