import { Component, OnDestroy, Optional } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';

@Component({
  selector: 'ngx-provider-detail',
  templateUrl: './provider-detail.component.html',
})
export class ProviderDetailModalComponent implements OnDestroy {
  public itemData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toaster: ToastrService,
    private providersService: PaymentSystemService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  deactivateItem() {
    this.providersService
      .deactivateProvider(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно деактивировали!');
        this.dialogRef.close('edit');
      });
  }

  activateItem() {
    this.providersService
      .activateProvider(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно активировали!');
        this.dialogRef.close('edit');
      });
  }

  blockItem() {
    this.providersService
      .blockProvider(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно заблокировали!');
        this.dialogRef.close('edit');
      });
  }
}
