import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';
import { CounteragentsService } from '../../../../services/counteragents/counteragents.service';
import { searchValue } from '../../../../utils/helpers';

@Component({
  selector: 'ngx-provider-actions-modal',
  templateUrl: './provider-actions-modal.component.html',
})
export class ProviderActionsModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public counteragents = [];
  public submitted: boolean = false;
  private itemData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private providersService: PaymentSystemService,
    private counteragentsService: CounteragentsService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(256)]],
      id: ['', Validators.required],
      isAggregator: [null, Validators.required],
    });
    if (this.itemData) {
      this.form.controls['name'].setValue(this.itemData.name);
      this.form.controls['id'].setValue(this.itemData.id);
      this.form.controls['isAggregator'].setValue(this.itemData.isAggregator);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getCounteragents(name = '') {
    this.counteragentsService
      .getListCounteragents(1, name)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => (this.counteragents = searchValue(res.items, name)));
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      if (this.itemData) {
        this.editProvider();
        return null;
      }

      this.createProvider();
    }
  }

  private createProvider() {
    this.providersService
      .createProvider({
        ...this.form.value,
        isAggregator: Boolean(this.form.value.isAggregator),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Успешно создано!');
        this.dialogRef.close('create');
      });
  }

  private editProvider() {
    this.providersService
      .editProvider(this.itemData.id, {
        ...this.form.value,
        isAggregator: Boolean(this.form.value.isAggregator),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Успешно отредактировано!');
        this.dialogRef.close('edit');
      });
  }
}
