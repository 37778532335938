import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneratePassword } from '../../../../utils';
import { AgentSystemService } from '../../../../services/agent-system/agents.service';
import { CounteragentsService } from '../../../../services/counteragents/counteragents.service';

@Component({
  selector: 'ngx-agents-action-modal',
  templateUrl: './agents-action-modal.component.html',
})
export class AgentsActionModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  itemData;
  counteragents = [];
  submitted = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private agentSystemService: AgentSystemService,
    private counteragentsService: CounteragentsService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(256)]],
      id: ['', Validators.required],
      apiKey: ['', Validators.required],
    });
    if (this.itemData) {
      this.form.controls['description'].setValue(this.itemData.description);
      this.form.controls['id'].setValue('for update');
      this.form.controls['apiKey'].setValue('for update');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getCounteragents(name = '') {
    this.counteragentsService
      .getListCounteragents(1, name)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => (this.counteragents = res.items));
  }

  public generatePassword() {
    const password = GeneratePassword(12, 1, 1, -1, -1);
    this.form.controls['apiKey'].setValue(password);
  }

  public onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      if (this.itemData) {
        this.agentSystemService
          .editAgent(this.itemData.id, this.form.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.toaster.success('Успешно отредактировано!');
            this.dialogRef.close('edit');
          });
        return;
      }

      this.agentSystemService
        .createAgent(this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toaster.success('Успешно создано!');
          this.dialogRef.close('create');
        });
    }
  }
}
