import { Pipe, PipeTransform } from '@angular/core';
import { FormatDate } from '../../../utils';

@Pipe({
  name: 'datePipe',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    return FormatDate(value, args);
  }
}
