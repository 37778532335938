import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentManagingService } from '../../../../services/payment-managing/payment-managing.service';
import { generateGuid } from '../../../../utils/toBase64';
import { getTimezone } from '../../../../utils';

export const paymentManagingRate = [
  {
    value: 'FixedRate',
    title: 'Фиксированная ставка',
    rate: 'сом',
  },
  {
    value: 'GradualRate',
    title: 'Градационная ставка',
  },
  {
    value: 'PercentageRate',
    title: 'Процентная ставка',
    rate: '%',
  },
];

@Component({
  templateUrl: './payment-managing-rates-actions-modal.component.html',
  styleUrls: ['./payment-managing-rates-actions-modal.component.scss'],
})
export class PaymentManagingRatesActionsModalComponent implements OnInit, OnDestroy {
  rateTypes = paymentManagingRate;
  form = this.fb.group({
    startDate: [''],
  });
  serviceId;
  item;

  submitted = false;
  rateTypesArr = [];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private paymentManagingService: PaymentManagingService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      const { startDate } = this.form.value;
      const formData = {
        startDate: startDate + 'T00:00' + getTimezone(true),
        rateRanges: this.rateTypesArr.map(item => item.form.value),
      };

      if (!this.item) {
        this.paymentManagingService
          .addRate(this.serviceId, formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
      if (this.item) {
        const parts = this.form.value.startDate.split('.');
        const formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

        this.paymentManagingService
          .editRate(this.serviceId, this.item.id, {
            ...formData,
            startDate: formattedDate,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
    }
  }

  formatDate(date: Date) {
    const newDate = new Date(date);
    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }

  addRates() {
    const rateForm = this.fb.group({
      rateType: [''],
      value: [''],
      startAmount: [0],
    });

    this.rateTypesArr.push({
      id: generateGuid(),
      form: rateForm,
    });
  }

  deleteRate(id) {
    this.rateTypesArr = this.rateTypesArr.filter(item => item.id !== id);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.addRates();
    }
    if (this.item) {
      this.form.controls['startDate'].setValue(this.formatDate(this.item.startDate));
      this.item.rate.rateRanges.forEach((item, index) => {
        const rateForm = this.fb.group({
          rateType: [item.rateType],
          value: [item.value],
          startAmount: [item.startAmount],
        });
        this.rateTypesArr.push({
          id: generateGuid(),
          form: rateForm,
        });
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
