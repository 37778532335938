<ng-template #form>
  @if (visible) {
    <div [class]="textareaInclude ? 'big-confirm p-2' : 'p-2'">
      @if (textareaInclude) {
        <label for="inputLastName" class="label">Напишите своё предложение партнеру</label>
        <textarea fullWidth [formControl]="control" placeholder="Написать ..." nbInput rows="2"></textarea>
      }
      <div class="d-flex justify-content-between">
        <button (click)="approve()" size="small" nbButton status="success" type="click" ghost>Подтвердить</button>
        <button (click)="decline()" size="small" nbButton status="danger" type="click" ghost>Отменить</button>
      </div>
    </div>
  }
</ng-template>
<button
  [class]="title.length > 25 ? 'w-100 default-confirm' : 'w-100'"
  nbButton
  (click)="onClick()"
  [status]="status"
  [nbPopover]="form">
  {{ title }}
</button>
<!-- <div class="ticker">
<button
  class="w-100"
  nbButton
  (click)="onClick()"
  [status]="status"
  [nbPopover]="form"
  >
  <section>
    <div class="scroll">
      <div>
        <span>
          {{ title }}
        </span>
      </div>
    </div>
  </section>
</button>
</div> -->
