import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './modules/core/core.module';
import { ThemeModule } from './modules/theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { AuthConfigModule } from './modules/auth/auth-config.module';
import { DemoNgZorroAntdModule } from './utils/ng-zorro-antd.module';
import { ToastrModule } from 'ngx-toastr';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { ComponentsModule } from './components/components.module';
import { AppConfigService } from './services/@core/config/app-config.service';

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    // NbChatModule.forRoot({
    //     messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    AuthConfigModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    ComponentsModule,
    DemoNgZorroAntdModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
      multi: true,
      deps: [AppConfigService],
    },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: 'Asia/Bishkek' },
    { provide: LOCALE_ID, useValue: 'ru-KG' },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
