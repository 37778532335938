import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AppConfig } from '../../../models/@core/config/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly http: HttpClient = inject(HttpClient);
  private config: AppConfig;

  public load(): Promise<AppConfig> {
    const headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate' });

    return lastValueFrom(this.http.get<AppConfig>('/assets/config.json', { headers })).then(data => {
      this.config = data;
      return data;
    });
  }

  public getConfig(): AppConfig {
    return this.config;
  }
}
