import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StatusBadgeComponent } from '../../components/shared/status-badge/status-badge.component';
import { ReadApiService } from '../@core/config/read-api.service';
import { ProviderResponse } from '../../models/commision-income/commision-income';
import { CreateProviders } from '../../models/providers/providers';

@Injectable({
  providedIn: 'root',
})
export class PaymentSystemService {
  public tableColumns = {
    name: {
      title: 'Название',
      type: 'text',
    },
    status: {
      title: 'Статус',
      type: 'custom',
      renderComponent: StatusBadgeComponent,
      componentInitFunction: StatusBadgeComponent.componentInit,
    },
    custom: {
      title: 'Детали',
      type: 'html',
      valuePrepareFunction: () => ` <a
                          class='color-a'
                        >
                          Подробнее
                        </a>`,
    },
  };

  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private paymentSystemUrl: string = this.environment.getApiKey('paymentSystemUrl');

  // ! Providers
  public getListProviders(page = 1, name = '', pageSize = 20): Observable<ProviderResponse> {
    return this.http.get<ProviderResponse>(
      this.paymentSystemUrl +
        `/Admin/api/v1/providers?page=${page}&orderType=desc&orderBy=CreatedAt&pageSize=${pageSize}`,
    );
  }

  getDetailProvider(id: number) {
    return this.http.get(this.paymentSystemUrl + `/Admin/api/v1/providers/${id}`);
  }

  activateProvider(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/providers/${id}/activate`, {});
  }

  deactivateProvider(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/providers/${id}/deactivate`, {});
  }

  blockProvider(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/providers/${id}/block`, {});
  }

  editProvider(id: number, data) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/providers/${id}`, data);
  }

  createProvider(data: CreateProviders) {
    return this.http.post(this.paymentSystemUrl + `/Admin/api/v1/providers`, data);
  }

  // ! Services
  createService(data) {
    return this.http.post(this.paymentSystemUrl + `/Admin/api/v1/services`, data);
  }

  editService(id: number, data) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/services/${id}`, data);
  }

  setProviderToService(id: number, data) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/services/${id}/set-provider`, data);
  }

  getListServices(page = 1, pageSize = 20) {
    return this.http.get(
      this.paymentSystemUrl +
        `/Admin/api/v1/services?page=${page}&orderType=desc&orderBy=CreatedAt&pageSize=${pageSize}`,
    );
  }

  getDetailService(id: number) {
    return this.http.get(this.paymentSystemUrl + `/Admin/api/v1/services/${id}`);
  }

  // ! Rates
  public getListProviderServices(page = 1, providerId: string) {
    return this.http.get(
      this.paymentSystemUrl + `/Admin/api/v1/providers/${providerId}/services?page=${page}&pageSize=2000`,
    );
  }

  getListAgentRates(page = 1, id: string, name: string) {
    return this.http.get(
      this.paymentSystemUrl +
        `/Admin/api/v1/providers/${id}/services/rates?&name=${name}&page=${page}&pageSize=20&orderBy=ProviderServiceId&orderType=DESC`,
    );
  }

  getDetailRate(providerId, serviceId, rateId) {
    return this.http.get(
      this.paymentSystemUrl + `/Admin/api/v1/providers/${providerId}/services/${serviceId}/rates/${rateId}`,
    );
  }

  addRate(providerId, serviceId, data) {
    return this.http.post(
      this.paymentSystemUrl + `/Admin/api/v1/providers/${providerId}/services/${serviceId}/rates`,
      data,
    );
  }

  deleteRate(providerId, serviceId, rateId) {
    return this.http.delete(
      this.paymentSystemUrl + `/Admin/api/v1/providers/${providerId}/services/${serviceId}/rates/${rateId}`,
    );
  }

  editRate(providerId, serviceId, rateId, data) {
    return this.http.put(
      this.paymentSystemUrl + `/Admin/api/v1/providers/${providerId}/services/${serviceId}/rates/${rateId}`,
      data,
    );
  }
}
