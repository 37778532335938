import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  AfterContentChecked,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { tableNumbering } from '../../../utils';

import { Subject } from 'rxjs';
import { takeUntil, pairwise } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { getPaginationIndex } from '../../../utils/tableNumbering';

@Component({
  selector: 'ngx-custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, AfterContentChecked, OnChanges {
  @Output() deleteItemEvent = new EventEmitter();
  @Output() openModalEvent = new EventEmitter();
  @Output() rowSelectEvent = new EventEmitter();
  @Output() changePageEvent = new EventEmitter();

  @Input() tableColumns;
  @Input() tableData;
  @Input() productName;
  @Input() filterForm;

  @Input() delete = true;
  @Input() deleteColumnName: string;
  @Input() edit = true;
  @Input() enableCustomPagination = true;

  @Input() actions = true;

  settings = {};
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.prepareSettings();
    this.prepareFiltersForm();
  }

  ngOnChanges(): void {
    this.prepareSettings();
  }

  ngAfterContentChecked(): void {
    setTimeout((): void => {
      this.cd.detectChanges();
    }, 0);
  }

  public onDelete(event): void {
    this.deleteItemEvent.emit(event.data);
  }

  public onRowSelect(event): void {
    this.setParams();
    setTimeout(() => {
      this.rowSelectEvent.emit(event.data);
    }, 1);
  }

  public onEdit(event): void {
    this.openModalEvent.emit(event.data);
  }

  public changePage(page): void {
    if (this.filterForm) {
      this.filterForm.controls.page.setValue(page);
      return;
    }
    this.changePageEvent.emit(page);
  }

  private prepareSettings(): void {
    const actions = {
      delete: this.delete,
      edit: this.edit,
      add: false,
      position: 'right',
      columnTitle: this.deleteColumnName ?? 'Опции',
    };

    this.settings = {
      mode: 'external',
      hideSubHeader: true,
      delete: {
        deleteButtonContent: `<i class="nb-trash"></i>`,
        confirmDelete: false,
      },
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
      },

      actions: this.actions ? actions : false,
      pager: {
        perPage: 20,
        display: true,
      },

      columns: {
        index: {
          title: '№',
          type: 'number',
          valuePrepareFunction: (value, row, cell) =>
            getPaginationIndex(this.tableData.page, row.row.index, this.tableData.pageSize),
        },
        ...this.tableColumns,
      },
    };
  }

  private prepareFiltersForm(): void {
    if (this.filterForm) {
      this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), pairwise()).subscribe(([prev, curr]) => {
        const { page: prevPage, ...prevObj } = prev;
        const { page: currPage, ...currObj } = curr;

        if (JSON.stringify(prevObj) !== JSON.stringify(currObj) && +currPage !== 1) {
          this.filterForm.controls.page.setValue(1);
        }
      });
      this.filterForm.patchValue(this.route.snapshot.queryParams);
    }
  }

  private setParams(): void {
    if (this.filterForm) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.filterForm.value,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }
}
