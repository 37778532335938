<nb-card>
  <nb-card-body>
    <nb-card-header class="row">
      <div class="col-12 mb-2 col-sm-7 col-md-8">
        <ngx-input-search (searchEvent)="getRates(1, $event)" placeholder="Название услуги"></ngx-input-search>
      </div>
      <div class="col-12 mb-2 col-sm-5 col-md-4">
        <button class="w-100" (click)="openAddServicesModal()" nbButton status="primary">Добавить</button>
      </div>
    </nb-card-header>
    <ngx-custom-table
      class="mt-5 d-block"
      (changePageEvent)="getRates($event)"
      (deleteItemEvent)="deleteService($event)"
      (openModalEvent)="openEditModal($event)"
      [tableColumns]="tableColumns"
      [tableData]="listServices"
      [productName]="'ни одного тарифа'">
    </ngx-custom-table>
  </nb-card-body>
</nb-card>
