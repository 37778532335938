<nb-card>
  <nb-card-body>
    @if (!helpersService.checkIsAuditor()) {
      <div class="d-flex justify-content-end mb-3">
        <button (click)="openAddServicesModal()" nbButton status="primary">Добавить</button>
      </div>
    }
    <ngx-custom-table
      class="mt-5 d-block"
      (changePageEvent)="getServices($event)"
      (deleteItemEvent)="deleteService($event)"
      [tableColumns]="tableColumns"
      [tableData]="listServices"
      [productName]="'ни одной услуги'"
      [delete]="!helpersService.checkIsAuditor() && true"
      [edit]="false">
    </ngx-custom-table> </nb-card-body
></nb-card>
