import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';
import { CustomDatePipe } from '../../../shared/date-pipe/date.pipe';
import {
  ProvidersRatesActionsModalComponent,
  providerRateTypes,
} from '../providers-rates-actions-modal/providers-rates-actions-modal.component';
import { Cell } from 'angular2-smart-table';

@Component({
  selector: 'ngx-payment-system-provider-rates',
  templateUrl: './list-payment-system-rates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListProvidersSystemRatesComponent implements OnInit, OnDestroy {
  @Input() providerId;
  public listServices;

  public tableColumns = {
    serviceName: {
      title: 'Услуга',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    startDate: {
      title: 'Действует с',
      type: 'text',
      valuePrepareFunction: (item: Date) => this.parseDate(item),
    },
    rateType: {
      title: 'Тип ставки',
      type: 'text',
      valuePrepareFunction: (item: undefined, cell: Cell) => this.getRateType(cell.getRow().getData().rate.rateType),
    },
    rate: {
      title: 'Ставка',
      type: 'text',
      valuePrepareFunction: item => this.getRateVal(item.rateValue, item.rateType),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private paymentSystemService: PaymentSystemService,
    private windowService: NbWindowService,
    private toaster: ToastrService,
    private datePipe: CustomDatePipe,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getRates();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getRates(page: number = 1, name: string = '') {
    this.paymentSystemService
      .getListAgentRates(page, this.providerId, name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.listServices = res;
        this.cd.markForCheck();
      });
  }

  public deleteService(data) {
    this.paymentSystemService
      .deleteRate(this.providerId, data.providerServiceId, data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toaster.success('Успешно удалено!');
        this.getRates();
      });
  }

  public openAddServicesModal() {
    this.openModal(false, ProvidersRatesActionsModalComponent, {
      title: 'Добавление тарифа',
      context: {
        providerId: this.providerId,
      },
    });
  }

  public openEditModal(data) {
    this.openModal(false, ProvidersRatesActionsModalComponent, {
      title: 'Редактирование тарифа',
      context: { providerId: this.providerId, item: data },
    });
  }

  public openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => (val === 'create' || val === 'edit') && this.getRates());
  }

  private parseDate(date: Date) {
    return this.datePipe.transform(date, 'dd.MM.yyyy');
  }

  getRateType(value: string): string {
    return providerRateTypes.find(item => item.value === value).title;
  }

  getRateVal(rate: number, cell: string): string {
    const elem = providerRateTypes.find(item => item.value === cell);
    return rate + ' ' + elem.rate;
  }
}
