import { Component, Input, OnInit } from '@angular/core';
import { Cell } from 'angular2-smart-table';
import { getAlertStatus } from '../../../utils';
import { statusIdentificate } from '../../../utils/const';

@Component({
  selector: 'ngx-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit {
  @Input() value: string;
  public inputStatus: string = '';
  public status: string = '';
  public alertStatus: string = '';

  ngOnInit() {
    this.inputStatus = statusIdentificate?.find(element => element.id === this.value)?.text;
  }

  public static componentInit(instance: StatusBadgeComponent, cell: Cell) {
    instance.status = statusIdentificate?.find(element => element.id === cell.getValue())?.text;
    instance.alertStatus = getAlertStatus(cell.getValue());
  }

  protected readonly getAlertStatus = getAlertStatus;
}
