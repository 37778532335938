import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-localization-inputs',
  templateUrl: './localization-inputs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizationInputsComponent {
  @Input() controlRu: AbstractControl = new FormControl();
  @Input() controlKy: AbstractControl = new FormControl();
  @Input() controlEn: AbstractControl = new FormControl();
  @Input() placeholder: string;
  @Input() submitted = false;
  @Input() formControlType = 'input';
  @Input() label: string;
}
