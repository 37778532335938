<nb-card>
  <nb-card-body>
    @if (!helpersService.checkIsAuditor()) {
      <div class="d-flex justify-content-end mb-3">
        <a [routerLink]="'/payment-managing/categories/detail' + '/create-subcategory/' + categoryId">
          <button nbButton status="primary">Добавить</button>
        </a>
      </div>
    }
    <ngx-custom-table
      class="mt-5 d-block"
      (changePageEvent)="getSubcategories($event)"
      (openModalEvent)="editCategory($event)"
      (rowSelectEvent)="onRowSelect($event)"
      [tableColumns]="tableColumns"
      [tableData]="subcategories"
      [productName]="'ни одной подкатегории'"
      [delete]="false"
      [edit]="!helpersService.checkIsAuditor() && true">
    </ngx-custom-table>
  </nb-card-body>
</nb-card>
