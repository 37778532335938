import { AppConfigService } from './app-config.service';
import { inject, Injectable } from '@angular/core';
import { AppConfig } from '../../../models/@core/config/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class ReadApiService {
  private readonly config: AppConfigService = inject(AppConfigService);

  public getApiKey(key: keyof AppConfig): string {
    const config: AppConfig = this.config.getConfig();
    return config ? config[key] : undefined;
  }
}
