<div>
  @if (itemData.status !== 'Active') {
    <ngx-confirm-buttons class="mt-3 d-block" (approveEvent)="activateItem()" status="success" title="Активировать">
    </ngx-confirm-buttons>
  }
  @if (itemData.status === 'Active') {
    <ngx-confirm-buttons class="mt-3 d-block" (approveEvent)="deactivateItem()" status="danger" title="Деактивировать">
    </ngx-confirm-buttons>
  }
  @if (itemData.status !== 'Blocked') {
    <ngx-confirm-buttons class="mt-3 d-block" (approveEvent)="blockItem()" status="danger" title="Заблокировать">
    </ngx-confirm-buttons>
  }
</div>
