<div>
  <div class="d-flex justify-content-end">
    @if (!helpersService.checkIsAuditor()) {
      <button (click)="createEvent.emit()" nbButton status="primary">Добавить</button>
    }
  </div>
  <div class="messengers">
    <ngx-custom-table
      (changePageEvent)="getData.emit()"
      (deleteItemEvent)="deleteEvent.emit($event)"
      (openModalEvent)="editEvent.emit($event)"
      [tableColumns]="tableColumns"
      [tableData]="data"
      [productName]="'ни одного сотрудника'"
      [actions]="!helpersService.checkIsAuditor() && true">
    </ngx-custom-table>
  </div>
</div>
