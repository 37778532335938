import { Component, OnDestroy, Optional } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentSystemService } from '../../../../services/agent-system/agents.service';

@Component({
  templateUrl: './agent-system-services-actions-modal.component.html',
})
export class AgentSystemServicesActionsModalComponent implements OnDestroy {
  itemData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toaster: ToastrService,
    private agentSystemService: AgentSystemService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  deactivateItem() {
    this.agentSystemService
      .deactivateService(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно деактивировали!');
        this.dialogRef.close('edit');
      });
  }

  activateItem() {
    this.agentSystemService
      .activateService(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно активировали!');
        this.dialogRef.close('edit');
      });
  }

  blockItem() {
    this.agentSystemService
      .blockService(this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно заблокировали!');
        this.dialogRef.close('edit');
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
