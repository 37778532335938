import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from '../../../../services/@core/http/loader.service';

@Component({
  selector: 'ngx-beneficiares',
  templateUrl: './beneficiares.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBeneficiaresComponent {
  @Output() deleteEvent = new EventEmitter();
  @Output() createEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() getData = new EventEmitter();

  @Input() data;
  tableColumns = {
    name: {
      title: 'ФИО',
      type: 'text',
      valuePrepareFunction: (cell, item) => item.surname + ' ' + item.name + ' ' + item.fatherName,
    },

    position: {
      title: 'Должность',
      type: 'text',
    },
  };

  constructor(public helpersService: LoaderService) {}
}
