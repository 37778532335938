import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAccordionModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbTreeGridModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { RouterModule } from '@angular/router';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { ValidationInputComponent } from './shared/validation-input/validation-input.component';
import { TableComponent } from './shared/table/table.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { DemoNgZorroAntdModule } from '../utils/ng-zorro-antd.module';
import { PhoneNumberInputComponent } from './shared/phone-number-input/phone-number-input.component';
import { ImgInputComponent } from './shared/img-input/img-input.component';
import { MultipleSearchSelectComponent } from './shared/multiple-search-select/multiple-search-select.component';
import { AvatarImgComponent } from './shared/avatar-img/avatar-img.component';
import { LocalizationInputsComponent } from './shared/localization-inputs/localization-inputs.component';
import { SearchSelectComponent } from './shared/search-select/search-select.component';
import { UseHttpImageSourcePipe } from './shared/secured-image/secured-image.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { LightboxImgComponent } from './shared/lightbox-img/lightbox-img.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { NzResultModule } from 'ng-zorro-antd/result';
import { StatusBadgeComponent } from './shared/status-badge/status-badge.component';
import { NbMomentDateModule } from '@nebular/moment';
import { OrderByPipe } from './shared/order-by-pipe/order-by.pipe';
import { GlobalLoaderComponent } from './shared/global-loader/global-loader.component';
import { BtnLoaderComponent } from './shared/btn-loader/btn-loader.component';
import { InputSearchComponent } from './shared/input-search/input-search.component';
import { CustomDatePipe } from './shared/date-pipe/date.pipe';
import { ProviderActionsModalComponent } from './payment-system/providers/provider-actions-modal/provider-actions-modal.component';
import { InfoContractorComponent } from './counteragents/info-contractor/info-contractor.component';
import { EmployeesComponent } from './counteragents/employees/list-employees/employees.component';
import { EmployeesActionsModalComponent } from './counteragents/employees/employees-actions-modal/employees-actions-modal.component';
import { ListBeneficiaresComponent } from './counteragents/beneficiares/list-beneficiaries/beneficiares.component';
import { BeneficiaresActionsComponent } from './counteragents/beneficiares/actions-beneficiares-modal/beneficiares-actions.component';
import { ProviderDetailModalComponent } from './payment-system/providers/provider-detail-modal/provider-detail.component';
import { AgentsActionModalComponent } from './agent-system/agents/agents-action-modal/agents-action-modal.component';
import { AgentsServicesActionsModalComponent } from './agent-system/agents/agents-services-actions-modal/agents-services-actions-modal.component';
import { AgentSystemServicesActionsModalComponent } from './agent-system/services/agent-system-services-actions-modal/agent-system-actions-modal.component';
import { SubcategoriesPaymentManagingComponent } from './payment-managing/categories/subcategories-payment-managing/subcategories-payment-managing.component';
import { PaymentManagingServiceActionsModalComponent } from './payment-managing/services/payment-managing-service-actions-modal/payment-managing-service-actions-modal.component';
import { ListAgentSystemServicesComponent } from './payment-system/list-agent-system-services/list-agent-system-services.component';
import { PassportDetailsComponent } from './counteragents/passport-details/passport-details.component';
import { ListAgentSystemRatesComponent } from './payment-system/list-agent-system-rates/list-agent-system-rates.component';
import { AgentsRatesActionsModalComponent } from './agent-system/agents/agents-rates-actions-modal/agents-rates-actions-modal.component';
import { ProvidersRatesActionsModalComponent } from './payment-system/providers/providers-rates-actions-modal/providers-rates-actions-modal.component';
import { ListProvidersSystemRatesComponent } from './payment-system/providers/list-payment-system-rates/list-payment-system-rates.component';
import { ListPaymentSystemProviderServicesComponent } from './payment-system/providers/list-payment-system-providers-services/list-payment-system-provider-services.component';
import { PaymentManagingRatesActionsModalComponent } from './payment-managing/services/payment-managing-rates-actions-modal/payment-managing-rates-actions-modal.component';
import { ListPaymentManagingRatesComponent } from './payment-managing/services/list-payment-managing-rates/list-payment-managing-rates.component';
import { TreeSelectComponent } from './shared/tree-select/tree-select.component';
import { Angular2SmartTableModule } from 'angular2-smart-table';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NbAlertModule,
    NbStepperModule,
    NbCardModule,
    ReactiveFormsModule,
    Angular2SmartTableModule,
    FormsModule,
    NbButtonModule,
    NbSelectModule,
    NbInputModule,
    NbPopoverModule,
    NbListModule,
    NbDatepickerModule,
    NzPaginationModule,
    NzSelectModule,
    NbTreeGridModule,
    NbAccordionModule,
    NbTabsetModule,
    NbIconModule,
    NzSliderModule,
    DemoNgZorroAntdModule,
    NbEvaIconsModule,
    NbBadgeModule,
    NzResultModule,
    NbCheckboxModule,
    NbMomentDateModule,
    NbSpinnerModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    UseHttpImageSourcePipe,
    SearchSelectComponent,
    LocalizationInputsComponent,
    MultipleSearchSelectComponent,
    AvatarImgComponent,
    PaginationComponent,
    LightboxImgComponent,
    ImgInputComponent,
    ValidationInputComponent,
    TableComponent,
    PhoneNumberInputComponent,
    StatusBadgeComponent,
    OrderByPipe,
    GlobalLoaderComponent,
    BtnLoaderComponent,
    InputSearchComponent,
    CustomDatePipe,
    ProviderActionsModalComponent,
    InfoContractorComponent,
    EmployeesComponent,
    EmployeesActionsModalComponent,
    ListBeneficiaresComponent,
    BeneficiaresActionsComponent,
    ProviderDetailModalComponent,
    AgentsActionModalComponent,
    AgentsServicesActionsModalComponent,
    AgentSystemServicesActionsModalComponent,
    SubcategoriesPaymentManagingComponent,
    PaymentManagingServiceActionsModalComponent,
    ListAgentSystemServicesComponent,
    PassportDetailsComponent,
    ListAgentSystemRatesComponent,
    AgentsRatesActionsModalComponent,
    ProvidersRatesActionsModalComponent,
    ListProvidersSystemRatesComponent,
    ListPaymentSystemProviderServicesComponent,
    PaymentManagingRatesActionsModalComponent,
    ListPaymentManagingRatesComponent,
    TreeSelectComponent,
  ],
  exports: [
    ConfirmDialogComponent,
    UseHttpImageSourcePipe,
    SearchSelectComponent,
    LocalizationInputsComponent,
    MultipleSearchSelectComponent,
    AvatarImgComponent,
    PaginationComponent,
    LightboxImgComponent,
    ImgInputComponent,
    ValidationInputComponent,
    TableComponent,
    PhoneNumberInputComponent,
    StatusBadgeComponent,
    OrderByPipe,
    GlobalLoaderComponent,
    BtnLoaderComponent,
    InputSearchComponent,
    CustomDatePipe,
    ProviderActionsModalComponent,
    InfoContractorComponent,
    EmployeesComponent,
    EmployeesActionsModalComponent,
    ListBeneficiaresComponent,
    BeneficiaresActionsComponent,
    ProviderDetailModalComponent,
    AgentsActionModalComponent,
    AgentsServicesActionsModalComponent,
    AgentSystemServicesActionsModalComponent,
    SubcategoriesPaymentManagingComponent,
    PaymentManagingServiceActionsModalComponent,
    ListAgentSystemServicesComponent,
    PassportDetailsComponent,
    ListAgentSystemRatesComponent,
    AgentsRatesActionsModalComponent,
    ProvidersRatesActionsModalComponent,
    ListProvidersSystemRatesComponent,
    ListPaymentSystemProviderServicesComponent,
    PaymentManagingRatesActionsModalComponent,
    ListPaymentManagingRatesComponent,
    TreeSelectComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
