import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentSystemService } from '../../../services/agent-system/agents.service';
import { AgentsServicesActionsModalComponent } from '../../agent-system/agents/agents-services-actions-modal/agents-services-actions-modal.component';
import { LoaderService } from '../../../services/@core/http/loader.service';

@Component({
  selector: 'ngx-payment-system-agent-services',
  templateUrl: './list-agent-system-services.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListAgentSystemServicesComponent implements OnInit, OnDestroy {
  @Input() agentId;
  public listServices;
  public tableColumns = {
    serviceName: {
      title: 'Название',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    description: {
      title: 'Описание',
      type: 'text',
    },
    isActive: {
      title: 'Активен',
      type: 'text',
      valuePrepareFunction: (bool: boolean) => (bool ? 'Да' : 'Нет'),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();
  private listServicesData;

  constructor(
    private agentSystemService: AgentSystemService,
    private windowService: NbWindowService,
    private toaster: ToastrService,
    private cd: ChangeDetectorRef,
    public helpersService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.getServices();
    this.getServicesList();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getServices(page = 1) {
    this.agentSystemService
      .getListServices(page, this.agentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.listServices = res;
        this.cd.markForCheck();
      });
  }

  public deleteService(data) {
    this.agentSystemService
      .removeService(this.agentId, data.serviceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Успешно удалено!');
        this.getServices();
      });
  }

  public openAddServicesModal() {
    this.openModal(false, AgentsServicesActionsModalComponent, {
      title: '' + 'Добавление услуг',
      context: { itemId: this.agentId, agentServices: this.listServicesData },
    });
  }

  public openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => (val === 'create' || val === 'edit') && this.getServices());
  }

  private getServicesList(page = 1) {
    this.agentSystemService.getListServices(page, this.agentId, 2000).subscribe(res => {
      this.listServicesData = res;
    });
  }
}
