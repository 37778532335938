import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
})
export class PhoneNumberInputComponent implements OnInit {
  @Input() control: AbstractControl = new FormControl();
  @Input() submitted = false;
  @Input() isRequired = true;
  @Input() regionCode = '+996';

  @ViewChild('input') input: ElementRef;
  value = '';
  maxLength = 9;

  onChange(val) {
    this.value = val;
    this.control.setValue(this.value);

    const newVal = val.replace(/^(0+|-|\.|\+996| |‒|996)|\s/g, '');

    if (newVal.length === 9) {
      const phone = this.numberWithSpaces(newVal, '### ## ## ##');
      this.value = phone;
      this.control.setValue(newVal);
      this.maxLength = 12;
    } else {
      this.maxLength = 20;
    }

    if (this.control.value.replaceAll(' ', '').length === 9) {
      this.control.patchValue(this.regionCode + this.control.value);
    }
  }

  numberWithSpaces(value, pattern) {
    let i = 0;
    const phone = value.toString().replace(/\D/gm, '');
    if (phone.length < 9) {
      return phone;
    }

    return pattern.replace(/#/g, _ => phone[i++]);
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe((val: string) => {
      if (val?.includes(this.regionCode) && !this.value) {
        this.onChange(val);
        this.input.nativeElement.focus();
        this.input.nativeElement.blur();
      }

      if (!val) {
        this.value = '';
      }
    });
  }
}
