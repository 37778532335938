<form class="rate-types" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Действует с</label>
    <input
      formControlName="startDate"
      fullWidth
      onfocus="(this.type='date')"
      type="text"
      format="dd.MM.yyyy"
      nbInput
      placeholder="Действует с" />
  </div>

  @if (rateTypesArr.length > 0) {
    @for (formPerm of rateTypesArr; track formPerm; let idx = $index) {
      <div class="d-flex justify-content-center align-items-center">
        <strong>{{ idx + 1 }}</strong>
        <button class="ms-3" type="button" (click)="deleteRate(formPerm.id)" ghost nbButton status="danger">
          <i style="font-size: 25px" class="nb-close"></i>
        </button>
      </div>
      <div class="row rates" [formGroup]="formPerm.form">
        <div class="form-group col-md-4">
          <label class="label">С какой суммы</label>
          <input
            formControlName="startAmount"
            fullWidth
            nbInput
            type="number"
            placeholder="Введите сумму"
            [status]="submitted && formPerm.form.get('startAmount').errors?.['required'] ? 'danger' : 'basic'" />
        </div>
        <div class="form-group col-md-4">
          <label class="label">Ставка</label>
          <input
            formControlName="value"
            fullWidth
            nbInput
            type="number"
            placeholder="Введите ставку"
            [status]="submitted && formPerm.form.get('value').errors?.['required'] ? 'danger' : 'basic'" />
        </div>
        <div class="form-group col-md-4">
          <label class="label">Тип ставки</label>
          <nb-select formControlName="rateType" fullWidth placeholder="Тип ставки">
            <nb-option value="FixedRate">Фиксированная ставка</nb-option>
            <nb-option value="PercentageRate">Процентная ставка</nb-option>
          </nb-select>
        </div>
      </div>
    }
  }
  <button class="d-flex mx-auto" type="button" nbButton status="primary" (click)="addRates()">Добавить ставку</button>

  <button class="d-flex mx-auto mt-3" type="submit" nbButton status="primary">Подтвердить</button>
</form>
