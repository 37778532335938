import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReadApiService } from '../@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class AgentSystemService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private paymentSystemUrl: string = this.environment.getApiKey('paymentSystemUrl');

  getListAgents(page = 1) {
    return this.http.get(
      this.paymentSystemUrl + `/Admin/api/v1/agents?page=${page}&orderType=desc&orderBy=CreatedAt&pageSize=20`,
    );
  }

  getDetailAgent(id: number) {
    return this.http.get(this.paymentSystemUrl + `/Admin/api/v1/agents/${id}`);
  }

  activateAgent(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/agents/${id}/activate`, {});
  }

  deactivateAgent(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/agents/${id}/deactivate`, {});
  }

  editAgent(id: number, data) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/agents/${id}`, data);
  }

  addServicesToAgent(id: number, data) {
    return this.http.post(this.paymentSystemUrl + `/Admin/api/v1/agents/${id}/services`, data);
  }

  createAgent(data) {
    return this.http.post(this.paymentSystemUrl + `/Admin/api/v1/agents`, data);
  }

  // ! Services
  public getListServices(page = 1, id: string, pageSize: number = 20) {
    return this.http.get(
      this.paymentSystemUrl + `/Admin/api/v1/agents/${id}/services?page=${page}&pageSize=${pageSize}`,
    );
  }

  activateService(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/services/${id}/activate`, {});
  }

  deactivateService(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/services/${id}/deactivate`, {});
  }

  blockService(id: number) {
    return this.http.put(this.paymentSystemUrl + `/Admin/api/v1/services/${id}/block`, {});
  }

  removeService(agentId, id) {
    return this.http.delete(this.paymentSystemUrl + `/Admin/api/v1/agents/${agentId}/services/${id}`);
  }

  // ! Rates

  getListAgentRates(page = 1, id, name) {
    return this.http.get(
      this.paymentSystemUrl +
        `/Admin/api/v1/agents/${id}/services/rates?name=${name}&page=${page}&pageSize=20&orderBy=AgentServiceId&orderType=DESC`,
    );
  }

  getDetailRate(agentId, serviceId, rateId) {
    return this.http.get(
      this.paymentSystemUrl + `/Admin/api/v1/agents/${agentId}/services/${serviceId}/rates/${rateId}`,
    );
  }

  addRate(agentId, serviceId, data) {
    return this.http.post(this.paymentSystemUrl + `/Admin/api/v1/agents/${agentId}/services/${serviceId}/rates`, data);
  }

  deleteRate(agentId, serviceId, rateId) {
    return this.http.delete(
      this.paymentSystemUrl + `/Admin/api/v1/agents/${agentId}/services/${serviceId}/rates/${rateId}`,
    );
  }

  editRate(agentId, serviceId, rateId, data) {
    return this.http.put(
      this.paymentSystemUrl + `/Admin/api/v1/agents/${agentId}/services/${serviceId}/rates/${rateId}`,
      data,
    );
  }
}
