import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentSystemService } from '../../../../services/agent-system/agents.service';
import { PaymentSystemService } from '../../../../services/payment-system/payment-system.service';

@Component({
  selector: 'ngx-agents-services-actions-modal',
  templateUrl: './agents-services-actions-modal.component.html',
  styleUrl: './agents-services-actions-modal.component.scss',
})
export class AgentsServicesActionsModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public services = [];
  public submitted = false;
  private itemId;
  private servicesData = [];
  private agentServices;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private agentSystemService: AgentSystemService,
    private paymentSystemService: PaymentSystemService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.getServices();
    this.form = this.fb.group({
      services: [[]],
    });
    if (this.itemId) {
      this.form.controls['services'].setValue(this.agentServices.items.map(item => item.serviceId));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.agentSystemService
        .addServicesToAgent(this.itemId, this.form.value.services)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.toaster.success('Успешно добавлено!');
          this.dialogRef.close('create');
        });
    }
  }

  public filterSelectValues(value: string) {
    this.services = this.servicesData.filter(e => e.description.toLowerCase().includes(value.toLowerCase()));
  }

  private getServices(name = '') {
    this.paymentSystemService
      .getListServices(1, 2000)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.services = res.items;
        this.servicesData = res.items;
      });
  }
}
