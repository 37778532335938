import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-img-input',
  templateUrl: './img-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgInputComponent implements OnDestroy {
  @Input() control: AbstractControl = new FormControl();
  @Input() submitted = false;
  @Input() imgSrc;
  changed = false;
  document;
  imageUrl;

  constructor(private sanitizer: DomSanitizer) {}

  onFileChange(event) {
    const file = event.target.files[0];
    this.createImageUrl(file);
    const formData = new FormData();
    formData.append('file', file, file.name);
    // this.imageUrl = this.createImageUrl(file);

    this.document = formData;
    this.control.setValue(this.document);
  }

  createImageUrl(file: File): void {
    // Use DomSanitizer to create a safe URL for the image
    try {
      const imageUrl = URL.createObjectURL(file);
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
    } catch (e) {
      console.error('Error creating image URL:', e);
      this.imageUrl = null;
    }
  }

  ngOnDestroy() {
    if (this.imageUrl) {
      try {
        URL.revokeObjectURL(this.imageUrl);
      } catch (e) {
        console.error('Error revoking image URL:', e);
      }
    }
  }
}
