<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Название поставщика</label>
    <ngx-validation-input [submitted]="submitted" [control]="form.get('name')" placeholder="Введите название">
    </ngx-validation-input>
  </div>
  <div class="form-group">
    <label class="label">Контрагент</label>
    <ngx-search-select
      [submitted]="submitted"
      [control]="form.get('id')"
      (searchEmit)="getCounteragents($event)"
      [data]="counteragents"
      returnVal="name"
      [placeholder]="'Выберите контрагента'">
    </ngx-search-select>
  </div>
  <div class="form-group">
    <label class="label">Является ли агрегатором</label>
    <nz-select
      [nzStatus]="submitted && form.controls.isAggregator.errors?.['required'] ? 'error' : ''"
      class="w-100"
      nzSize="large"
      formControlName="isAggregator"
      nzPlaceHolder="Является ли агрегатором">
      <nz-option nzValue="true" nzLabel="Да"></nz-option>
      <nz-option nzValue="false" nzLabel="Нет"></nz-option>
    </nz-select>
  </div>
  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Подтвердить</button>
</form>
