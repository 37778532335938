<form [formGroup]="form" (ngSubmit)="onFirstSubmit()">
  <div class="form-group">
    <label class="label">ФИО</label>
    <input type="text" nbInput fullWidth placeholder="Введите ФИО" formControlName="name" />
  </div>
  <div class="form-group">
    <label class="label">Должность</label>
    <input nbInput fullWidth placeholder="Введите должность" formControlName="position" />
  </div>
  <div class="form-group">
    <label class="label">Номер телефона</label>

    <ngx-search-select
      returnVal="phoneNumber"
      (searchEmit)="getCustomers($event)"
      [control]="form.get('userId')"
      [isRequired]="true"
      [submitted]="submitted"
      [data]="customers"
      [placeholder]="'Выберите номер телефона'"></ngx-search-select>
  </div>
  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Подтвердить</button>
</form>
