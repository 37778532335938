import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, tap } from 'rxjs/operators';

interface CanActivate {
  canActivate(): Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      tap(d => {
        if (!d.isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
      }),
      map(data => data.isAuthenticated),
    );
  }
}
