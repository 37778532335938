import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentManagingService } from '../../../../services/payment-managing/payment-managing.service';
import { paymentMethods } from '../../../../utils/const';

@Component({
  selector: 'ngx-payment-managing-service-actions-modal',
  templateUrl: './payment-managing-service-actions-modal.component.html',
})
export class PaymentManagingServiceActionsModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public listCategories = [];
  public submitted = false;
  public paymentMethods = paymentMethods;
  public modalType;
  private itemData;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private paymentManagingService: PaymentManagingService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCategories(page = 1): void {
    this.paymentManagingService
      .getListCategories(page)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => (this.listCategories = res.items));
  }

  public onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      switch (this.modalType) {
        case 'order':
          this.editOrderToService();
          break;
        case 'categories':
          this.editCategoriesToService();
          break;
        case 'paymentMethods':
          this.editPaymentMethodsToService();
          break;
        case 'service-logo':
          this.editLogoToService();
          break;
        case 'category-logo':
          this.editLogoToCategory();
          break;
      }
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      paymentMethodTypes: [[]],
      ids: [[]],
      order: [''],
      file: [''],
      parentIds: [[]],
    });
  }

  private initData(): void {
    if (this.itemData) {
      switch (this.modalType) {
        case 'order':
        case 'categories':
        case 'paymentMethods':
          this.form.controls['paymentMethodTypes'].setValue(
            this.itemData?.availablePaymentMethods.map(item => item.paymentMethodType),
          );
          this.form.controls['ids'].setValue(this.itemData?.categories.map(item => item.id));
          this.form.controls['order'].setValue(this.itemData?.order);
          this.form.controls['parentIds'].setValue(
            this.itemData?.categories.map(item => item.parentId).filter(parentId => parentId !== undefined),
          );
          break;
      }
    }
  }

  private editOrderToService(): void {
    this.paymentManagingService
      .setOrderToService(this.itemData.id, {
        order: this.form.value.order,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editSuccessfully('create');
      });
  }

  private editCategoriesToService(): void {
    this.paymentManagingService
      .setCategoriesToService(this.itemData.id, this.form.value.ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.editSuccessfully('edit', 'Категория успешно добавлена!');
        },
        () => {
          this.toaster.error('Вы не можете удалить все категории');
        },
      );
  }

  private editPaymentMethodsToService(): void {
    this.paymentManagingService
      .setPaymentMethodsToService(this.itemData.id, {
        paymentMethodTypes: this.form.value.paymentMethodTypes,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editSuccessfully('edit');
      });
  }

  private editLogoToService(): void {
    this.paymentManagingService
      .setLogoToService(this.itemData.id, this.form.value.file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editSuccessfully('edit');
      });
  }

  private editLogoToCategory(): void {
    this.paymentManagingService
      .setLogoToCategory(this.itemData.id, this.form.value.file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editSuccessfully('edit');
      });
  }

  private editSuccessfully(action: string, message = 'Успешно отредактировано!'): void {
    this.toaster.success(message);
    this.dialogRef.close(action);
  }
}
