<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Услуги</label>
    <ngx-search-select
      [submitted]="submitted"
      [control]="form.get('services')"
      (searchEmit)="filterSelectValues($event)"
      [data]="services"
      returnVal="name"
      [placeholder]="'Выберите услуги'"
      mode="multiple"></ngx-search-select>
  </div>
  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Подтвердить</button>
</form>
