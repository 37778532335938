import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from '../../../../services/customers/customers.service';

@Component({
  selector: 'ngx-employees-actions-modal',
  templateUrl: './employees-actions-modal.component.html',
})
export class EmployeesActionsModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  itemData;
  customers;
  createEmployee;
  editEmployee;

  submitted;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private customersService: CustomersService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      userId: ['', Validators.required],
      position: ['Manager', Validators.required],
      name: ['', Validators.required],
    });
    this.getCustomers('');

    if (this.itemData) {
      this.form.controls['userId'].setValue(this.itemData.userId);
      this.form.controls['position'].setValue(this.itemData.position);
      this.form.controls['name'].setValue(this.itemData.name);
    }
  }

  getCustomers(val) {
    this.customersService
      .getCustomerByPhoneNumber(val)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.customers = res.items;
      });
  }

  onFirstSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      if (this.itemData) {
        this.editEmployee(this.itemData.id, this.form.value, this.dialogRef);
        return;
      }
      this.createEmployee(this.form.value, this.dialogRef);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
