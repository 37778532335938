import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AgentSystemService } from '../../../services/agent-system/agents.service';
import {
  AgentsRatesActionsModalComponent,
  rateTypes,
} from '../../agent-system/agents/agents-rates-actions-modal/agents-rates-actions-modal.component';
import { CustomDatePipe } from '../../shared/date-pipe/date.pipe';
import { Cell } from 'angular2-smart-table';

@Component({
  selector: 'ngx-payment-system-agent-rates',
  templateUrl: './list-agent-system-rates.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListAgentSystemRatesComponent implements OnInit, OnDestroy {
  @Input() agentId;
  listServices;

  tableColumns = {
    serviceName: {
      title: 'Услуга',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    startDate: {
      title: 'Действует с',
      type: 'text',
      valuePrepareFunction: (item: Date) => this.parseDate(item),
    },
    rateType: {
      title: 'Тип ставки',
      type: 'text',
      valuePrepareFunction: (item: undefined, cell: Cell) => this.getRateType(cell.getRow().getData().rate.rateType),
    },
    rate: {
      title: 'Ставка',
      type: 'text',
      valuePrepareFunction: item => this.getRateVal(item.rateValue, item.rateType),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private agentSystemService: AgentSystemService,
    private windowService: NbWindowService,
    private toaster: ToastrService,
    private router: Router,
    private datePipe: CustomDatePipe,
    private cd: ChangeDetectorRef,
  ) {}

  parseDate(date) {
    return this.datePipe.transform(date, 'dd.MM.yyyy');
  }

  getRateType(value: string): string {
    return rateTypes.find(item => item.value === value).title;
  }

  getRateVal(rate: number, cell: string): string {
    const elem = rateTypes.find(item => item.value === cell);
    return rate + ' ' + elem.rate;
  }

  getRates(page = 1, name = '') {
    this.agentSystemService
      .getListAgentRates(page, this.agentId, name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.listServices = res;
        this.cd.markForCheck();
      });
  }

  deleteService(data) {
    this.agentSystemService
      .deleteRate(this.agentId, data.agentServiceId, data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Успешно удалено!');
        this.getRates();
      });
  }

  public openAddServicesModal() {
    this.openModal(false, AgentsRatesActionsModalComponent, {
      title: 'Добавление тарифа',
      context: {
        agentId: this.agentId,
        agentServices: this.listServices,
      },
    });
  }

  public openEditModal(data) {
    this.openModal(false, AgentsRatesActionsModalComponent, {
      title: 'Редактирование тарифа',
      context: { agentId: this.agentId, item: data },
    });
  }

  public openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => (val === 'create' || val === 'edit') && this.getRates());
  }

  ngOnInit(): void {
    this.getRates();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
