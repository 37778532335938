import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  getUserData() {
    const { userData } = JSON.parse(localStorage.getItem('0-sanarip_systems_admin'));
    return userData;
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe();
    sessionStorage.clear();
    localStorage.clear();
  }

  getAccessToken() {
    return this.oidcSecurityService.getAccessToken();
  }

  async checkAuth() {
    const state$ = this.oidcSecurityService.isAuthenticated();
    return lastValueFrom(state$);
  }

  login() {
    this.oidcSecurityService.authorize();
  }
}
