import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ReadApiService } from '../@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class CounteragentsService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private counteragentsUrl: string = this.environment.getApiKey('counteragentsUrl');
  private customerDataUrl: string = this.environment.getApiKey('customerDataUrl');

  public getListCounteragents(page, filter) {
    return this.http.get(this.counteragentsUrl + `/admin/api/v1/contractors?page=${page}&pageSize=20`);
  }

  getDetailCounteragent(id) {
    return this.http.get(this.counteragentsUrl + `/admin/api/v1/identifications/${id}`);
  }

  getContractorInfo(data) {
    return this.http.get(
      this.counteragentsUrl +
        `/admin/api/v1/contractors/info?legalForm=${data.legalForm}&inn=${data.inn}&urlOfPatent=${data.urlOfPatent}`,
    );
  }

  createContractor(data) {
    return this.http.post(this.counteragentsUrl + `/admin/api/v1/contractors`, data);
  }

  getCustomerData(id) {
    return this.http.get(this.customerDataUrl + `/admin/api/v1/customers/${id}`);
  }
}
