<div class="d-flex">
  <span
    [ngClass]="submitted && control.errors?.['required'] ? 'validation' : ''"
    class="input-group-text"
    id="basic-addon1"
    >+996</span
  >

  <input
    #input
    aria-describedby="basic-addon1"
    class="custom-input-mask"
    type="text"
    fullWidth
    nbInput
    [maxlength]="maxLength"
    [value]="value"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    placeholder="555555555"
    [status]="isRequired && submitted && control.errors?.['required'] ? 'danger' : 'basic'" />

  <!--  [(ngModel)]="value"  (keydown)="handlePhone($event)" maxlength="12"-->
</div>

<!-- [formControl]="control" -->
