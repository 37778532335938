import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { genderEnum, maritalStatus, residenceLocationEnum } from '../../../utils';

@Component({
  selector: 'ngx-passport-details',
  templateUrl: './passport-details.component.html',
  styleUrls: ['./passport-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassportDetailsComponent {
  @Input() customerData;
  @Input() showPhoneNumber = false;
  maritalStatus;

  getMaritalStatus() {
    return maritalStatus.find(item => item.value === this.customerData.identificationInformation.maritalStatus)?.text;
  }

  getGender() {
    return genderEnum.find(e => e.value === this.customerData.identificationInformation.gender).text;
  }

  getResidenceLoc() {
    return residenceLocationEnum.find(
      e => e.value === this.customerData.identificationInformation.residenceAddress.region,
    )?.text;
  }
}
