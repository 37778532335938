import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReadApiService } from '../@core/config/read-api.service';
import {
  CategoriesByIdResponse,
  CreateCategoryParams,
  ServicesResponse,
} from '../../models/payment-managing/payment-managing';

@Injectable({
  providedIn: 'root',
})
export class PaymentManagingService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private paymentManagingUrl: string = this.environment.getApiKey('paymentManagingUrl');

  // ! Categories
  public getListCategories(page: number = 1, parentId: string = '') {
    return this.http.get(
      this.paymentManagingUrl +
        `/Admin/api/v1/categories?${
          parentId ? `parentId=${parentId}&` : ''
        }page=${page}&orderType=desc&orderBy=CreatedAt&pageSize=20`,
    );
  }

  public getDetailCategory(id: string): Observable<CategoriesByIdResponse> {
    return this.http.get<CategoriesByIdResponse>(this.paymentManagingUrl + `/Admin/api/v1/categories/${id}`);
  }

  public getCategoryServices(id: string, page, status) {
    return this.http.get(
      this.paymentManagingUrl +
        `/Admin/api/v1/categories/${id}/services?page=${page}&onlyActive=${status}&orderType=desc&orderBy=CreatedAt&pageSize=20`,
    );
  }

  public activateCategory(id: string) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/categories/${id}/activate`, {});
  }

  public deactivateCategory(id: string) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/categories/${id}/deactivate`, {});
  }

  public editCategory(id: number, data) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/categories/${id}`, data);
  }

  public createCategory(params: CreateCategoryParams): Observable<CategoriesByIdResponse> {
    return this.http.post<CategoriesByIdResponse>(this.paymentManagingUrl + `/Admin/api/v1/categories`, params);
  }

  setLogoToCategory(id: number, data) {
    return this.http.patch(this.paymentManagingUrl + `/Admin/api/v1/categories/${id}/logo`, data);
  }

  // ! Services
  getListServices(form) {
    return this.http.get(
      this.paymentManagingUrl +
        `/Admin/api/v1/services?&name=${form.name}&page=${form.page}&orderType=desc&orderBy=CreatedAt&pageSize=20`,
    );
  }

  activateService(id: string) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/activate`, {});
  }

  deactivateService(id: string) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/deactivate`, {});
  }

  blockService(id: string) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/block`, {});
  }

  public setCategoriesToService(id: string, data) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/categories`, data);
  }

  setPaymentMethodsToService(id: number, data) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/payment-methods`, data);
  }

  setLogoToService(id: number, data) {
    return this.http.patch(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/logo`, data);
  }

  setOrderToService(id: number, data) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/set-order`, data);
  }

  public getDetailService(id: string): Observable<ServicesResponse> {
    return this.http.get<ServicesResponse>(this.paymentManagingUrl + `/Admin/api/v1/services/${id}`);
  }

  // ! Rates

  getListServiceRates(page = 1, id, name) {
    return this.http.get(
      this.paymentManagingUrl +
        `/Admin/api/v1/services/${id}/rates?serviceName=${name}&page=${page}&pageSize=20&orderBy=CreatedAt&orderType=DESC`,
    );
  }

  getDetailRate(id) {
    return this.http.get(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/rates`);
  }

  addRate(id, data) {
    return this.http.post(this.paymentManagingUrl + `/Admin/api/v1/services/${id}/rates`, data);
  }

  deleteRate(serviceId, rateId) {
    return this.http.delete(this.paymentManagingUrl + `/Admin/api/v1/services/${serviceId}/rates/${rateId}`);
  }

  editRate(serviceId, rateId, data) {
    return this.http.put(this.paymentManagingUrl + `/Admin/api/v1/services/${serviceId}/rates/${rateId}`, data);
  }
}
