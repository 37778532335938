<form [formGroup]="form">
  @if (modalType === 'order') {
    <div class="form-group">
      <label class="label">Порядок</label>
      <input
        type="number"
        nbInput
        fullWidth
        placeholder="Введите порядок"
        formControlName="order"
        [status]="submitted && form.get('order').errors?.['required'] ? 'danger' : 'basic'" />
    </div>
  }
  @if (modalType === 'categories') {
    <div class="form-group">
      <label class="label">Категории</label>
      <ngx-tree-select
        class="ms-3"
        [submitted]="submitted"
        [control]="form.get('ids')"
        [additionalControl]="form.get('parentIds')"
        [data]="listCategories"
        returnVal="name"
        [placeholder]="'Выберите категории'"
        mode="multiple">
      </ngx-tree-select>
    </div>
  }
  @if (modalType === 'paymentMethods') {
    <div class="form-group">
      <label class="label">Способы оплаты</label>
      <ngx-search-select
        [submitted]="submitted"
        [control]="form.get('paymentMethodTypes')"
        [data]="paymentMethods"
        returnVal="name"
        [placeholder]="'Выберите способы оплаты'"
        mode="multiple"></ngx-search-select>
    </div>
  }
  @if (modalType === 'service-logo' || modalType === 'category-logo') {
    <div class="form-group row">
      <label class="label col-sm-3 col-form-label">Логотоп</label>
      <div class="col-sm-9">
        <ngx-img-input [control]="form.get('file')" [submitted]="submitted" [imgSrc]="form.get('file').value">
        </ngx-img-input>
      </div>
    </div>
  }
  <div class="d-flex mx-auto justify-content-center">
    <ngx-button title="Подтвердить" (clickEvent)="onSubmit()"></ngx-button>
  </div>
</form>
