import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { trEngToRusOwnerST } from '../../../utils';
import { CounteragentsService } from '../../../services/counteragents/counteragents.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'ngx-info-contractor',
  templateUrl: './info-contractor.component.html',
  styleUrls: ['./info-contractor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoContractorComponent implements OnInit {
  @Input() data;
  @Input() employees;

  translateOwnerShipType = trEngToRusOwnerST;
  customerData;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private counteragentsService: CounteragentsService,
    private cdr: ChangeDetectorRef,
  ) {}

  getCustomerData() {
    const customerId = this.employees.items.find(item => item.position === 'Manager' && item)?.userId;
    if (customerId) {
      this.counteragentsService
        .getCustomerData(customerId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: any) => {
            this.customerData = data;
            this.cdr.markForCheck();
          },
        });
    }
  }
  ngOnInit(): void {
    this.getCustomerData();
  }
}
