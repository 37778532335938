import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTimezone } from '../../../../utils/helpers';
import { AgentSystemService } from '../../../../services/agent-system/agents.service';

export const rateTypes = [
  {
    value: 'FixedRewardRate',
    title: 'Фиксированное вознаграждение от агента',
    rate: 'сом',
  },
  {
    value: 'PercentageRewardRate',
    title: 'Процентное вознаграждение от агента',
    rate: '%',
  },
  {
    value: 'FixedCompensationRate',
    title: 'Фиксированное возмещение агенту',
    rate: 'сом',
  },
  {
    value: 'PercentageCompensationRate',
    title: 'Процентное возмещение агенту',
    rate: '%',
  },
];

@Component({
  selector: 'ngx-agents-rates-actions-modal',
  templateUrl: './agents-rates-actions-modal.component.html',
})
export class AgentsRatesActionsModalComponent implements OnInit, OnDestroy {
  rateTypes = rateTypes;
  form = this.fb.group({
    serviceId: [''],
    startDate: [''],
    rateType: [''],
    value: [''],
  });
  agentId;
  item;
  services = [];
  servicesData = [];
  submitted = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private agentSystemService: AgentSystemService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.getServices();

    if (this.item) {
      this.form.controls['serviceId'].setValue(this.item.agentServiceId);
      this.form.controls['startDate'].setValue(this.formatDate(this.item.startDate));
      this.form.controls['rateType'].setValue(this.item.rate.rateType);
      this.form.controls['value'].setValue(this.item.rate.rateValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getServices(name = '') {
    this.agentSystemService
      .getListServices(1, this.agentId, 2000)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.services = res.items;
        this.servicesData = res.items;
      });
  }

  public filterServicesData(value: string) {
    this.services = this.servicesData.filter(e => e.description.toLowerCase().includes(value.toLowerCase()));
  }

  public onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      if (!this.item) {
        this.agentSystemService
          .addRate(this.agentId, this.form.value.serviceId, {
            ...this.form.value,
            startDate: this.form.value.startDate + 'T00:00' + getTimezone(true),
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
      if (this.item) {
        const parts = this.form.value.startDate.split('.');
        const formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

        this.agentSystemService
          .editRate(this.agentId, this.item.agentServiceId, this.item.id, {
            ...this.form.value,
            startDate: formattedDate,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          });
      }
    }
  }

  private formatDate(date: Date) {
    const newDate = new Date(date);
    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }
}
