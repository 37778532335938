import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomDatePipe } from '../../../shared/date-pipe/date.pipe';
import { PaymentManagingService } from '../../../../services/payment-managing/payment-managing.service';
import {
  PaymentManagingRatesActionsModalComponent,
  paymentManagingRate,
} from '../payment-managing-rates-actions-modal/payment-managing-rates-actions-modal.component';
import { Cell } from 'angular2-smart-table';

@Component({
  selector: 'ngx-payment-managing-rates',
  templateUrl: './list-payment-managing-rates.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPaymentManagingRatesComponent implements OnInit, OnDestroy {
  @Input() serviceId;
  listServices;

  tableColumns = {
    serviceName: {
      title: 'Услуга',
      type: 'text',
      valuePrepareFunction: item => item.ru,
    },
    startDate: {
      title: 'Действует с',
      type: 'text',
      valuePrepareFunction: item => this.parseDate(item),
    },
    rateType: {
      title: 'Тип ставки',
      type: 'text',
      valuePrepareFunction: (item: undefined, cell: Cell) => this.getRateType(cell.getRow().getData().rate.rateRanges),
    },
    rate: {
      title: 'Ставка',
      type: 'text',
      valuePrepareFunction: item => this.getRateVal(item.rateRanges),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private paymentManagingService: PaymentManagingService,
    private windowService: NbWindowService,
    private toaster: ToastrService,
    private router: Router,
    private datePipe: CustomDatePipe,
    private cd: ChangeDetectorRef,
  ) {}

  parseDate(date) {
    return this.datePipe.transform(date, 'dd.MM.yyyy');
  }

  getRateType(arr: any): string {
    return arr.length === 1
      ? arr.map(el => paymentManagingRate.find(item => item.value === el.rateType).title)
      : 'Градационная ставка';
  }

  getRateVal(arr: any): string {
    return arr.length > 1
      ? arr.map(
          el =>
            `| c
                      ${el.startAmount} сом -
                      ${el.value}
                      ${paymentManagingRate.find(item => item.value === el.rateType).rate} `,
        )
      : arr.map(el => el.value + ' ' + paymentManagingRate.find(item => item.value === el.rateType).rate);
  }

  getRates(page = 1, name = '') {
    this.paymentManagingService
      .getListServiceRates(page, this.serviceId, name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.listServices = res;
        this.cd.markForCheck();
      });
  }

  deleteService(data) {
    this.paymentManagingService
      .deleteRate(this.serviceId, data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Успешно удалено!');
        this.getRates();
      });
  }

  public openAddServicesModal() {
    this.openModal(false, PaymentManagingRatesActionsModalComponent, {
      title: 'Добавление тарифа',
      context: {
        serviceId: this.serviceId,
        agentServices: this.listServices,
      },
    });
  }

  public openEditModal(data) {
    this.openModal(false, PaymentManagingRatesActionsModalComponent, {
      title: 'Редактирование тарифа',
      context: { serviceId: this.serviceId, item: data },
    });
  }

  public openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => (val === 'create' || val === 'edit') && this.getRates());
  }

  ngOnInit(): void {
    this.getRates();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
