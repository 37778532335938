import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';
import { LoaderService } from '../../../../services/@core/http/loader.service';

@Component({
  selector: 'ngx-employees',
  templateUrl: './employees.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeesComponent {
  @Output() deleteEvent = new EventEmitter();
  @Output() createEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() getData = new EventEmitter();

  @Input() data;
  tableColumns = {
    name: {
      title: 'ФИО',
      type: 'text',
    },

    position: {
      title: 'Должность',
      type: 'text',
    },
  };

  constructor(public helpersService: LoaderService) {}
}
